@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-scrollbar {
    /* display: none; */
  }
  font-family: Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  /* -ms-overflow-style: none; IE and Edge */
  /* scrollbar-width: none; */
}
.slick-dots {
  display: none !important;
}
.parallax {
  /* background-image: url('./assets/images/our-service-bg.webp'); */
  /* min-height: 30vh; */
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: cover;
}

.parallax-home {
  /* background-image: url('./assets/images/fssai-food-bg.webp'); */
  /* min-height: 30vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat; */
  background-size: cover;
}

.registrationImage {
  background-image: url("https://images.moneycontrol.com/static-mcnews/2023/06/financial_services3-770x433.jpg?impolicy=website&width=770&height=431");
}

.right-arrow-icon-fixed-size {
  padding-top: 8px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-benfit-icon-fixed-size {
  padding-top: 8px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.food-benfit-icon-fixed-size {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-title::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 7%;
  background: red;
  height: 35px;
  width: 3px;
}

.contents {
  background: #c3c;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: 0%;
}

.nav-submenu {
  background-color: transparent;
  background: linear-gradient(to left, #fff 50%, #dedede 50%) right;
  background-size: 200%;
  transition: 0.5s ease-out;
  border-radius: 5px;
}

.nav-submenu:hover {
  background-position: left;
}

.form-button {
  color: #000;
  border: 2px solid #f00000;
  background-color: transparent;
  background: linear-gradient(to left, #fff 50%, #f00000 50%) right;
  background-size: 200%;
  transition: 0.5s ease-out;
  border-radius: 5px;
}

.form-button:hover {
  background-position: left;
  color: #fff;
}
