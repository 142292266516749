.header-background {
  background-color: #fff;
  /* background-color: transparent; */
  /* background-image: linear-gradient(90deg, #ffffff 71%, #b0b0b0 100%); */
}

/* For mobile devices */
@media (max-width: 768px) {
  .header-background {
    /* background-image: linear-gradient(180deg, #ffffff 71%, #b0b0b0 100%); */
  }
}
